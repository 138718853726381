export const generalMixin = {
  data() {
    return {
      browser: null,
      debug: true,
      isMobile: null,
      isMobileDevelopment: null,
      isLocalhost: window.location.host.indexOf('localhost') != -1,
      yesNoOptions: [
        {
          value: 1
          ,text: "Ja"
        }
        ,{
          value: 0
          ,text: "Nej"
        }
      ],
      formRules: {
        required: value => value !== undefined && !!value.toString().trim() || 'Värde måste anges',
        checked(value, message = 'Val måste göras') {
//          console.log('formRules.checked', value)
//          console.log('formRules.checked typeof', typeof value)
          switch (typeof value) {
            case 'boolean':
              //single checkbox
              return value || message
            case 'string':
              //radio buttons
              return value.length !== 0 || message
          }
          //multiple checkboxes
          return value.length !== 0 || message
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return (value.toString().trim().length == 0 || pattern.test(value.toString().trim())) || 'Ogiltig e-postadress'
        },
        personNo: value => {
          const pattern = /^[0-9]{4}-[0-9]{2}-[0-9]{2}-[0-9]{4}$/;
          return (value.toString().trim().length == 0 || pattern.test(value.toString().trim())) || 'Ange i formatet ÅÅÅÅMMDDNNNN'
        },
        maxLength(value, maxLength) {
          return value.length <= maxLength || 'Max ' + maxLength + ' tecken'
        },
        positiveInteger(value, limitData) {
          let has_min = limitData && limitData.min !== undefined
          let has_max = limitData && limitData.max !== undefined
          if (value.toString().trim().length == 0) {
            return true
          }
          const pattern = /^[0-9]+$/;
          const isPositiveInteger = pattern.test(value.toString().trim())
          //is positive integer
          if (has_min && has_max) {
            //has both min and max
            if (!isPositiveInteger || value < limitData.min || value > limitData.max) {
              return 'Ange ett heltal mellan ' + limitData.min + ' och ' + limitData.max
            }
            return true
          }
          if (has_min) {
            //has only min
            if (!isPositiveInteger || value < limitData.min) {
              return 'Ange ett heltal som minst ' + limitData.min
            }
            return true
          }
          if (has_max) {
            //has only max
            if (!isPositiveInteger || value < limitData.max) {
              return 'Ange ett heltal som max ' + limitData.max
            }
            return true
          }
          if (!isPositiveInteger) {
            return 'Ange ett positivt heltal'
          }
          return true
        },
        organizationNumber: value => {
          const pattern = /^[0-9]{6}-[0-9]{4}$/
          return (value.toString().trim().length == 0 || pattern.test(value.toString().trim())) || 'Ange i formatet NNNNNN-NNNN'
        },
        date: value => {
          const pattern = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
          return (value.toString().trim().length == 0 || pattern.test(value.toString().trim())) || 'Ange i formatet ÅÅÅÅ-MM-DD'
        },
        time: value => {
          const pattern = /^[0-9]{2}:[0-9]{2}$/;
          return (value.toString().trim().length == 0 || pattern.test(value.toString().trim())) || 'Ange i formatet HH-MM'
        }
      }
    }
  },
  methods: {
    log() {
      if (this.debug) {
        console.log(arguments)
      }
    },
    hasProperty(object, property) {
      return Object.prototype.hasOwnProperty.call(object, property)
    },
    getIsIOS() {
      let flag = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
      return flag
    },
    isNumeric: function(value) {
      return value.toString().trim().length != 0 && !Number.isNaN(value)
    },
    isPositiveInteger: function(value) {
      const pattern = /^[0-9]+$/;
      return pattern.test(value.toString().trim())
    },
    formatPrice(value, slim) {
      if (slim && value == 0) {
        return 'Gratis'
      }
      let decimals = slim && Math.round(value) == value ? 0 : 2
      let decimal_sep = ','
      let thousands_sep = ' '
      var n = parseFloat(value)
      var sign = (n < 0) ? '-' : ''
      var i = parseInt(n = Math.abs(n).toFixed(decimals)) + ''
      var j = ((j = i.length) > 3) ? j % 3 : 0
      return sign + (j ? i.substr(0, j) + thousands_sep : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands_sep) + (decimals ? decimal_sep + Math.abs(n - i).toFixed(decimals).slice(2) : '') + ' kr'
    },
    getBrowser() {
      // Get the user-agent string 
      let userAgentString = navigator.userAgent
//      alert(userAgentString)
      // Detect Chrome 
      let chromeAgent = userAgentString.indexOf("Chrome") > -1
      // Detect Internet Explorer 
      let IExplorerAgent = userAgentString.indexOf("MSIE") > -1 || userAgentString.indexOf("rv:") > -1
      // Detect Firefox 
      let firefoxAgent = userAgentString.indexOf("Firefox") > -1
      // Detect Safari
      let safariAgent = userAgentString.indexOf("Safari") > -1
      // Discard Safari since it also matches Chrome 
      if ((chromeAgent) && (safariAgent)) {
        safariAgent = false
      }
      // Detect Opera 
      let operaAgent = userAgentString.indexOf("OP") > -1
      // Discard Chrome since it also matches Opera      
      if ((chromeAgent) && (operaAgent)) {
        chromeAgent = false
      }
      if (userAgentString.indexOf("CriOS") > -1) {
        //fix by kaxig :)
        return 'chrome'
      }
      if (IExplorerAgent) {
        return 'ie'
      } else if (safariAgent) {
        return 'safari'
      } else if (operaAgent) {
        return 'opera'
      } else if (firefoxAgent) {
        return 'firefox'
      }
      return 'chrome'
    },
    getTopUrlData() {
      let url
      if (this.isInIframe) {
        //in iframe, use parentrUrl
        url = this.parentUrl
      } else if (process.env.NODE_ENV == 'development') {
        //when npm run serve on localhost, use dev domain
        url = 'https://booking-dev.webomaten.se'
      } else {
        //use dev/prod booking url
        url = 'https://' + window.location.host
      }
      let queryString_ar = []
      if (this.isInIframe) {
        //append kobraPgeId
        queryString_ar.push('pg=' + this.kobraPgeId)
      }
      return {
        url: url,
        queryString_ar: queryString_ar
      }
    },
    getAbsUrl: function(relUrl) {
      let absUrl = window.location.origin + relUrl
      return absUrl
    },
    generalParseErrorArray(error_ar, target = 'html') {
      let delimiter = '<br>'
      switch (target) {
        case 'js':
          delimiter = '/n'
        break
      }
      return error_ar.join(delimiter)
    },
    onNonSuccessApiCall(returnData) {
      switch (returnData.errorType) {
        case 'old-event-data':
          //reload selected event data
          this.reloadSelectedEventData();
          return false
        /*
        case 'unauthorized':
          //logout
          this.$store.dispatch('logout')
          if (returnData.error_ar) {
            //alert error
            alert(this.generalParseErrorArray(returnData.error_ar, 'js'))
          }
          //go home
          this.$router.replace({ name: 'home' })
          return false
        case 'no-object-access':
          if (returnData.error_ar) {
            //alert error
            alert(this.generalParseErrorArray(returnData.error_ar, 'js'))
          }
          //go back
          this.$router.back()
          return false
        */
      }
      return true
    }
  },
  created() {
    this.isMobile = this.$isMobile()
    this.isMobileDevelopment = this.isMobile && process.env.NODE_ENV != 'production'
    this.isIOS = this.getIsIOS()
    this.browser = this.getBrowser()
    this.isSystemBrowser = this.isIOS ? this.browser === 'safari' : true
//    alert('browser: ' + this.browser)
//    alert('isSystemBrowser: ' + this.isSystemBrowser)
  }
}
