import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    firstRouterHasLoaded: false,
    agent: '',
    agentPrivate: '',
    eventGroup: '',
    event: '',
    parentUrl: '',
    isEventGroup: false,
    isSingleEvent: false,
    isInIframe: false,
    isAdmin: false,
    kobraPgeId: '',
    callbackData: null
  },
  mutations: {
    SET_FIRST_ROUTER_HAS_LOADED(state) {
      state.firstRouterHasLoaded = true
    },
    SET_AGENT(state, value) {
      state.agent = value
    },
    SET_AGENT_PRIVATE(state, value) {
      state.agentPrivate = value
    },
    SET_IS_ADMIN(state, value) {
      state.isAdmin = value
    },
    SET_PARENT_URL(state, value) {
      state.parentUrl = value
    },
    SET_IS_IN_IFRAME(state, value) {
      state.isInIframe = value
    },
    SET_KOBRA_PGE_ID(state, value) {
      state.kobraPgeId = value
    },
    SET_CALLBACK_DATA(state, value) {
      state.callbackData = value
    },
    SET_EVENT(state, value) {
      state.event = value
    },
    SET_IS_EVENT_GROUP(state, value) {
      state.isEventGroup = value
    },
    SET_IS_SINGLE_EVENT(state, value) {
      state.isSingleEvent = value
    },
  },
  getters: {
    agent: state => {
      return state.agent
    },
    agentPrivate: state => {
      return state.agentPrivate
    },
    isAdmin: state => {
      return state.isAdmin
    },
    event: state => {
      return state.event
    },
    eventGroup: state => {
      return state.eventGroup
    },
    isSingleEvent: state => {
      return state.isSingleEvent
    },
    isEventGroup: state => {
      return state.isEventGroup
    },
    callbackData: state => {
      return state.callbackData
    },
  },
  actions: {
    setFirstRouterHasLoaded({ commit }) {
      commit('SET_FIRST_ROUTER_HAS_LOADED', true)
    },
    setAgent({ commit }, value) {
      commit('SET_AGENT', value)
    },
    setAgentPrivate({ commit }, value) {
      commit('SET_AGENT_PRIVATE', value)
      commit('SET_IS_ADMIN', (value.length))
    },
    setParentUrl({ commit }, value) {
      commit('SET_PARENT_URL', value)
    },
    setKobraPgeId({ commit }, value) {
      commit('SET_KOBRA_PGE_ID', value)
    },
    setIsInIframe({ commit }, value) {
      commit('SET_IS_IN_IFRAME', value)
    },
    setCallbackData({ commit }, value) {
      commit('SET_CALLBACK_DATA', value)
    },
    setEventGroup({ commit }, value) {
      commit('SET_EVENT_GROUP', value)
      commit('SET_IS_EVENT_GROUP', (value.length))
    },
    setEvent({ commit }, value) {
      commit('SET_EVENT', value)
      commit('SET_IS_SINGLE_EVENT', (value.length))
    },
  },
  modules: {
  }
})
