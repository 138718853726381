export const dateMixin = {
  data() {
    return {
      mask_default: 'YYYY-MM-DD',
      weekDays: ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag', 'Söndag'],
      months: ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December']
    }
  },
  methods: {
    date_format: function(date, mask) {
      const m = this.date_getMonth(date)
      const mm = this.date_leadingZero(m, 2)
      const d = this.date_getDay(date)
      const dd = this.date_leadingZero(d, 2)
      const yyyy = this.date_getYear(date)
      switch (mask) {
        //YYYY-MM-DD
        default:
          return yyyy + '-' + mm + '-' + dd
      }
    },
    date_timeFormat: function(date, mask) {
      const h = this.date_getHour(date)
      const hh = this.date_leadingZero(h, 2)
      const m = this.date_getMinute(date)
      const mm = this.date_leadingZero(m, 2)
      switch (mask) {
        case 'H:MM':
          return h + ':' + mm
        //HH:MM
        default:
          return hh + ':' + mm
      }
    },
    date_outputFriendlyDateTime: function(string) {
      let date = this.date_convertToDate(string)
      return this.date_getWeekDayName(date) + ' ' + this.date_getDay(date) + ' ' + this.date_getMonthName(date) + ' kl ' + this.date_timeFormat(date)
    },
    date_outputFriendlyDateTimeSpan: function(string1, string2) {
      let from = this.date_convertToDate(string1)
      let to = this.date_convertToDate(string2)
      return this.date_getWeekDayName(from) + ' ' + this.date_getDay(from) + ' ' + this.date_getMonthName(from) + ' kl ' + this.date_timeFormat(from) + ' - ' + this.date_timeFormat(to)
    },
    date_convertToDate: function(string, mask) {
      let dateTimeParts, date, time, dateParts, timeParts, year, month, day, hour, minute
      switch (mask) {
        //YYYY-MM-DD
        default:
          dateTimeParts = string.split(' ')
          date = dateTimeParts[0]
          console.log('date', date)
          dateParts = date.split('-')
          year = parseInt(dateParts[0])
          month = parseInt(dateParts[1])
          day = parseInt(dateParts[2])
          if (dateTimeParts.length > 1) {
            //has time
            time = dateTimeParts[1]
            timeParts = time.split(':')
            hour = timeParts[0]
            minute = timeParts[1]
            return new Date(year, month - 1, day, hour, minute)
          }
          return new Date(year, month - 1, day)
      }
    },
    date_dateAdd: function(formattedDate, count, mask, unit) {
      let _mask = (mask) ? mask : this.mask_default
//      let date = (typeof dateOrString === "string") ? this.date_convertToDate(dateOrString, mask) : dateOrString
      let date =  this.date_convertToDate(formattedDate, _mask)
      switch (unit) {
        default:
          //day
          date.setDate(date.getDate() + count)
      }
      return this.date_format(date, _mask)
    },
    date_leadingZero: function(value, length) {
      let string = value.toString()
      for (let i = 0; i < length - string.length; i++) {
        string = '0' + string
      }
      return string
    },
    date_getNow: function() {
      return new Date()
    },
    date_getSecond: function(date) {
      return date.getSeconds()
    },
    date_getMinute: function(date) {
      return date.getMinutes()
    },
    date_getHour: function(date) {
      return date.getHours()
    },
    date_getDay: function(date) {
      return date.getDate()
    },
    date_getWeekDayName: function(date) {
      let number = this.date_getWeekDay(date)
      return this.weekDays[number - 1]
    },
    date_getWeekDay: function(date) {
      let weekDay = date.getDay()
      return (weekDay == 0) ? 7 : weekDay
    },
    date_getMonth: function(date) {
      return date.getMonth() + 1
    },
    date_getMonthName: function(date) {
      let number = this.date_getMonth(date)
      return this.months[number - 1]
    },
    date_getYear: function(date) {
      return date.getFullYear()
    },
  }
}