export const iframeMixin = {
  data() {
    return {
    }
  },
  methods: {
		iframe_getParameterByName(name) {
			name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]")
			let regex = new RegExp("[\\?&]" + name + "=([^&#]*)")
			let results = regex.exec(location.search)
			return results == null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "))
		},
    iframe_scrollToTop() {
      if (!window.parent) {
        return
      }
      let hostLocation = this.iframe_getParameterByName("parentUrl")
//      console.log('iframe_scrollToTop', hostLocation)
			window.parent.postMessage("{" +
			"\"fnName\": \"scrollHostToTop\"," +
			"\"fnParams\": []" +
			"}",
			hostLocation)
    }
  }
}