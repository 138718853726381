<template>
  <v-snackbar
    v-model="snackbar"
  >
    <div v-html="message"></div>
    <div class="d-flex justify-center">
      <v-btn
        color="primary"
        text
        dark
        @click="close()"
      >
        Stäng
      </v-btn>
    </div>
  </v-snackbar>
</template>
<script>
import { snackBarBus } from '../main'

export default {
  data() {
    return {
      snackbar: false,
      message: ''
    }
  },
  methods: {
    close() {
      this.snackbar = false
      this.message = ''
    }
  },
  created() {
    snackBarBus.$on('message', (message) => {
      this.message = message
      this.snackbar = true
    })
  }
}
</script>