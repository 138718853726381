<template>
  <div>
    <v-dialog
      :value="paymentInProgress"
      width="400"
      persistent
    >
      <v-card>
        <v-card-text class="text-center">
          <img class="pb-8" src="@/assets/swish_popup.png" />
          <div class="text-body-2 text-center"
            v-html="message"
          >
          </div>
          <div class="mt-4 text-center">
            <v-btn
              v-if="state !== null"
              outlined
              @click="cancel()"
              small
            >
              Avbryt
            </v-btn>
          </div>
        </v-card-text>
        <!--
        <v-card-actions v-if="showCloseButton">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="close('button')"
          >
            Avbryt
          </v-btn>
        </v-card-actions>
        -->
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from 'axios'
import { snackBarBus } from '../main'
import { mapGetters } from 'vuex'
import { generalMixin } from '../mixins/general'
import { urlMixin } from '../mixins/url'

export default {
  mixins: [generalMixin, urlMixin],
  props: {
    paymentInProgress: {
      type: Boolean,
      default: false
    },
    onCreateData: {
      type: Object,
      default: null
    }
  }, 
  data() {
    return {
      state: null,
      location: null,
      payeePaymentReference: null
    }
  },
  computed: {
    ...mapGetters(['agent', 'parentUrl', 'isInIframe']),
    message() {
      switch (this.state) {
        case 'init':
          return 'Kontaktar Swish - var god vänta...'
        case 'openApp':
          return this.isMobile ? 'Öppna Swish-appen och genomför betalningen' : 'Öppna Swish-appen på din mobil eller surfplatta och genomför betalningen'
        case 'checking':
          return 'Kontrollerar betalningsstatus'
        case 'cancel':
          return 'Avbryter'
      }
      return ''
    }
  },
  watch: {
    state(newValue, oldValue) {
      this.log('state changed from', oldValue, 'to', newValue)
    },
    paymentInProgress(newValue) {
      if (newValue) {
        if (this.state == null) {
          //only set if is null, cause it may already have been set to next state, before this watch kicks in, and we don't want to override that with 'init'
          this.state = 'init'
        }
      }
    },
    onCreateData(newValue) {
      if (newValue) {
        this.location = newValue.swishResponseData.location
        this.payeePaymentReference = newValue.payeePaymentReference
        this.state = 'openApp'
        if (newValue.swishResponseData.paymentRequestToken) {
          //mobile - direct to swish app
          //locate to swish app with the token - important to encodeURIComponent for the callbackurl!
          let url='swish://paymentrequest?token=' + newValue.swishResponseData.paymentRequestToken
          //callbackurl is only for apps!
//          let url='swish://paymentrequest?token=' + newValue.swishResponseData.paymentRequestToken + '&callbackurl=' + encodeURIComponent(this.getForegroundCallbackUrl());
          this.log(url)
          let swishWindow = window.open(url)
          //log swishWindow otherwise lint complains on that the var is never used :)
          this.log('swishWindow', swishWindow)
        } else {
          //desktop - continue to check
          this.setTimeoutOrderStatusCheck()
        }
      }
    }
  },
  methods: {
    /* not used (for apps only)
    getForegroundCallbackUrl() {
      let topUrlData = this.getTopUrlData()
      topUrlData.queryString_ar.push('callbackdata=swish_' + this.payeePaymentReference)
      let callbackUrl= topUrlData.url + '?' + topUrlData.queryString_ar.join('&')
      this.log('callbackUrl', callbackUrl)
      return callbackUrl
    },
    */
    setTimeoutOrderStatusCheck() {
      setTimeout(this.checkOrderStatus, 1000)
    },
    checkOrderStatus() {
      const config = {}
      let relUrl = '/booking/check-payment-request'
      const url = this.url_composeApiUrl(relUrl)
      const postData = {
        agent: this.agent,
        payeePaymentReference: this.payeePaymentReference
      }
      this.log('check-payment-request post', postData)
      axios.post(url, postData, config)
        .then(response => {
          this.log('check-payment-request response', response)
          if (response.data.success) {
            //success
            this.onOrderStatus(response.data.returnData)
          } else if (this.onNonSuccessApiCall(response.data.returnData)) {
            snackBarBus.$emit('message', this.generalParseErrorArray(response.data.returnData.error_ar))
            this.reset()
          }
        })
        .catch(error => {
          snackBarBus.$emit('message', error.toString())
          this.reset()
        })
    },
    onOrderStatus(data) {
      switch (data.statusData.label) {
        case 'notFound':
          //this should never happen, but you never know, do you
          snackBarBus.$emit('message', 'Bokningen sparades ej')
          this.reset()
        break
        case 'settled':
          this.$emit('settled', data)
        break
        case 'aborted':
          this.reset()
          if (data.statusData.msg.length) {
            snackBarBus.$emit('message', data.statusData.msg)
          }
          this.reset()
        break
        case 'created':
          if (this.state === null) {
            this.log('do not check anymore due to cancelled from app')
          } else {
            //continue to check
            this.log('continue to check')
            this.setTimeoutOrderStatusCheck()
          }
        break
        default:
          snackBarBus.$emit('message', 'Ett okänt fel uppstod (' + data.statusData.label + ')')
          this.reset()
      }
    },
    cancel() {
      this.state = null
      const config = {}
      let relUrl = '/booking/cancel-payment-request'
      const url = this.url_composeApiUrl(relUrl)
      const postData = {
        agent: this.agent,
        payeePaymentReference: this.payeePaymentReference
      }
      this.log('cancel-payment-request post', postData)
      axios.post(url, postData, config)
        .then(response => {
          this.log('cancel-payment-request response', response)
          if (response.data.success) {
            //success
            this.onCancelRequest(response.data.returnData)
          } else if (this.onNonSuccessApiCall(response.data.returnData)) {
            snackBarBus.$emit('message', this.generalParseErrorArray(response.data.returnData.error_ar))
            this.reset()
          }
        })
        .catch(error => {
          snackBarBus.$emit('message', error.toString())
          this.reset()
        })
    },
    onCancelRequest(data) {
      if (data.settled) {
        //was settled, do nothing and let the checkOrderStatus find out)
      } else {
        //reset
        this.reset()
      }
    },
    reset() {
      this.$emit('close')
      this.state = null
    }
  },
  destroyed() {
  },
  created() {
  }
}
</script>