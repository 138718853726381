import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  //default
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '*',
    redirect: { name: 'home' }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  console.log('router beforeEach')
  if (store.state.firstRouterHasLoaded) {
    //this is not initial router load, just go on
    next()
  } else {
    //set firstRouterHasLoaded to true so we do not consider this again
    store.dispatch('setFirstRouterHasLoaded')
    store.dispatch('setAgent', to.query.agent)
    if (to.query.eventgroup && to.query.eventgroup.length) {
      store.dispatch('setEventGroup', to.query.eventgroup)
    }
    if (to.query.event && to.query.event.length) {
      store.dispatch('setEvent', to.query.event)
    }
    if (to.query.agentPrivate && to.query.agentPrivate.length) {
      store.dispatch('setAgentPrivate', to.query.agentPrivate)
    }
    if (to.query.parentUrl) {
      store.dispatch('setParentUrl', to.query.parentUrl)
      store.dispatch('setKobraPgeId', to.query.kobrapgeid)
      store.dispatch('setIsInIframe', true)
    }
    /* not used
    if (to.query.callbackData) {
      store.dispatch('setCallbackData', to.query.callbackdata)
    }
    */
    next()
  }
})

export default router
