<template>
  <v-app>
    <v-main>
      <transition
        name="fade"
        mode="out-in"
      >
        <router-view></router-view>
      </transition>
    </v-main>
  </v-app>
</template>

<script>
//import axios from 'axios'
//import { mapGetters } from 'vuex'
//import { snackBarBus } from './main'
//import { generalMixin } from './mixins/general'
//import { urlMixin } from './mixins/url'
//import { authMixin } from './mixins/auth'

export default {
  name: 'App',
  data: () => ({
  }),
//  mixins: [generalMixin, urlMixin, authMixin],
  computed: {
//    ...mapGetters(['isLoggedIn', 'isAdmin', 'agentType', 'agentName', 'userName', 'loggedInData', 'hasMultipleAgentAccess', 'traceOutput', 'showWelcomeAlert', 'version', 'isLoggedInAs', 'isDeveloper', 'getHomeRoute', 'access_token']),
  },
  methods: {
  },
  created() {
  },
}
</script>

<style>
  /* fix no scroll for calendar */
  html {
    overflow-y: unset !important;
  }
  /* fix no scroll for iframe */
  .v-application {
    line-height: unset !important;
  }
</style>
