import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

import sv from 'vuetify/lib/locale/sv'

export default new Vuetify({
  lang: {
    locales: { sv },
    current: 'sv'
  },
  theme: {
    themes: {
    }
  }
});
