<template>
  <v-container>
    <p
      class="text-h5 text-center purple--text text--lighten-2"
      v-if="isAdmin"
    >
      Adminbokning
    </p>
    <transition name="view">
      <!--booking-->
      <v-container v-show="view === 'booking'">
        <v-row>
          <v-col>
            <v-stepper v-model="bookingStep">
              <!--step header-->
              <v-stepper-header>
                <v-stepper-step
                  :complete="bookingStep > 1"
                  step="1"
                  :editable="bookingStep > 1"
                  edit-icon="$complete"
                >
                  {{ labelEvent }}
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                  :complete="bookingStep > 2"
                  step="2"
                  :editable="bookingStep > 2"
                  edit-icon="$complete"
                >
                  Tid
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="3">
                  Boka
                </v-stepper-step>
              </v-stepper-header>
              <!--step items-->
              <v-stepper-items>
                <!--step 1) events--->
                <v-stepper-content step="1">
                  <v-skeleton-loader
                    class="mx-auto"
                    type="list-item-two-line"
                    v-if="!dataIsLoaded_1"
                    max-width="600"
                  ></v-skeleton-loader>
                  <template v-else-if="events.length == 0">
                    <p>Det finns inga bokningsbara {{ labelEvents | lowercase }}</p>
                  </template>
                  <template v-else>
                    <v-card
                      class="mx-auto"
                      max-width="600"
                      elevation="0"
                    >
                      <v-list three-line>
                        <template
                          v-for="(event, index) of events"
                        >
                          <v-list-item
                            :key="event.id"
                          >
                            <v-list-item-content>
                              <v-list-item-title v-text="event.title"></v-list-item-title>
                              <v-list-item-subtitle v-html="event.description" class="mb-2"></v-list-item-subtitle>
                              <div class="text-body-2 font-weight-bold">{{ event.priceData.hasCtp ? 'Vuxen' : 'Pris' }}: {{ event.priceData.isFree ? 'Gratis' : event.priceData.priceFormatted }}</div>
                              <template v-if="event.priceData.hasCtp">
                                <div v-for="(clientType, index) of event.priceData.clientTypes" :key="index">
                                  <template v-if="clientType.hasAge">
                                    <div
                                      v-for="(price, index2) of clientType.prices" :key="index2"
                                      class="text-body-2 font-weight-bold"
                                    >
                                      {{ clientType.namePlural }} {{ price.fromAge }}-{{ price.toAge}} år: {{ price.priceFormatted }}
                                    </div>
                                  </template>
                                  <template v-else>
                                    <div
                                      class="text-body-2 font-weight-bold"
                                    >
                                      {{ clientType.namePlural }}: {{ clientType.prices[0].priceFormatted }}
                                    </div>
                                  </template>
                                </div>
                              </template>
                              <div class="mt-2">
                                <v-btn
                                  @click="showFullEvent(event)"
                                  color="primary"
                                  outlined
                                >
                                  Läs mer
                                </v-btn>
                                <v-btn
                                  @click="selectEvent(event)"
                                  color="primary"
                                  class="ml-4"
                                >
                                  Boka
                                </v-btn>
                              </div>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider
                            :key="index"
                          ></v-divider>
                        </template>
                      </v-list>
                    </v-card>
                  </template>
                </v-stepper-content>
                <v-stepper-content step="2">
                  <v-card
                    flat
                    class="mb-8"
                  >
                    <v-overlay
                      absolute
                      :value="dataLoadInProgress"
                      opacity="0.7"
                      color="#FFFFFF"
                    >
                    </v-overlay>
                    <template v-show="showCalendar">
                      <!--event title-->
                      <div
                        class="text-h5 text-center mb-2"
                        v-if="selectedEvent"
                      >
                        Boka {{ selectedEvent.title }}
                      </div>
                      <!--client count select-->
                      <v-container v-show="multipleAppointmentType_client">
                        <v-row
                          align="center"
                          justify="center"
                          v-if="!clientCount"
                        >
                          <v-col
                            cols="12"
                            sm="8"
                            class="text-center text-body-1"
                          >
                            För att se vilka tider som går att boka, var god välj hur många personer det är i ditt sällskap
                          </v-col>
                        </v-row>
                        <v-row
                          align="center"
                          justify="center"
                        >
                          <v-col
                            cols="12"
                            sm="4"
                          >
                            <v-select
                              v-model="clientCount"
                              :items="clientCounts"
                              label="Välj antal personer"
                              hide-details
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-container>
                      <!--saloon select-->
                      <v-container v-show="showCalendar && saloonsSelectableByClient">
                        <v-row
                          align="center"
                          justify="center"
                        >
                          <v-col>
                            <v-chip-group
                              mandatory
                              active-class="primary--text"
                              v-model="saloonId"
                              @change="onSaloonChange()"
                              :show-arrows="false"
                              class="xCenter"
                            >
                              <v-chip
                                v-for="saloon of saloons"
                                :key="saloon.id"
                                :value="saloon.id"
                              >
                                {{ saloon.name }}
                              </v-chip>
                            </v-chip-group>
                          </v-col>
                        </v-row>
                      </v-container>
                      <!--session type switch-->
                      <template v-if="sessionType == 'slot'">
                        <!--slot-->
                        <!--calendar header-->
                        <v-sheet
                          height="64"
                          v-show="showCalendar"
                        >
                          <v-toolbar
                            flat
                          >
                            <!--
                            <v-btn
                              outlined
                              class="mr-4"
                              color="grey darken-2"
                              @click="goToToday"
                            >
                              Idag
                            </v-btn>
                            -->
                            <v-btn
                              text
                              small
                              color="grey darken-2"
                              @click="showPrevDays"
                              :disabled="!canBrowsePrevDays"
                            >
                              <v-icon left>
                                mdi-chevron-left
                              </v-icon>
                              Tidigare
                            </v-btn>
                            <v-spacer></v-spacer>
                            <!--
                            <v-toolbar-title v-if="$refs.calendar">
                              {{ $refs.calendar.title }}
                            </v-toolbar-title>
                            -->
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              small
                              color="grey darken-2"
                              @click="showNextDays"
                              :disabled="!canBrowseNextDays"
                            >
                              Senare
                              <v-icon right>
                                mdi-chevron-right
                              </v-icon>
                            </v-btn>
                          </v-toolbar>
                        </v-sheet>
                        <v-container>
                          <v-row>
                            <!--loop slotDays-->
                            <v-col
                              v-for="i of dayCountToShow"
                              :key="i"
                            >
                              <template v-if="slotDays[i - 1]">
                                <div class="c-day-date" :class="{ 'c-day-red': slotDays[i - 1].red }">
                                  <div class="c-day-day" v-text="slotDays[i - 1].day"></div>
                                  <div class="c-day-month" v-text="slotDays[i - 1].month"></div>
                                  <div class="c-day-weekday" v-text="slotDays[i - 1].weekday"></div>
                                </div>
                                <div class="c-day-time-holder">
                                  <!--loop slots-->
                                  <div class="c-day-time"
                                    v-for="(eventTime, index) of slotDays[i - 1].eventTimes"
                                    :key="index"
                                    v-show="eventTime.isVisible"
                                    @click="selectEventTime(eventTime)"
                                    :class="{ unavailable: !eventTime.isAvailable, selected: eventTime.selected }"
                                  >
                                    <div v-text="eventTime.name"></div>
                                    <div v-show="eventTime.availableCount != 0">{{ eventTime.availableCount }} {{ eventTime.availableCount == 1 ? 'plats' : 'platser' }} kvar</div>
                                  </div>
                                </div>
                              </template>
                            </v-col>
                          </v-row>
                        </v-container>
                        <!--
                        <v-calendar
                          ref="calendar"
                          v-show="showCalendar"
                          type="custom-weekly"
                          color="primary"
                          @click:event="selectEventTime"
                          :events="eventTimes"
                          :start="start"
                          :weekdays="weekdays"
                          :event-height="eventHeight"
                        >
                          <template v-slot:event="props">
                            <div class="_eventTime" :class="{ unavailable: !props.event.isAvailable }">
                              <span>{{ props.event.name }}</span>
                            </div>
                          </template>
                        </v-calendar>
                        -->
                      </template>
                    </template>
                    <template v-if="!dataLoadInProgress">
                      <div if="selectedEventTimes.length == 1" class="text-center my-4 text-h6">
                        {{ selectedEventsTimeOutput_atCalendar}}
                      </div>
                      <div class="d-flex justify-space-between align-end px-4">
                        <v-btn
                          color="secondary"
                          @click="goHome()"
                          small
                          outlined
                        >
                          Återgå
                        </v-btn>
                        <v-btn
                          color="primary"
                          @click="goToStep(3)"
                          :disabled="selectedEventTimes.length == 0"
                        >
                          {{ bookTimesButtonText }}
                        </v-btn>
                      </div>
                    </template>
                  </v-card>
                </v-stepper-content>
                <v-stepper-content
                  step="3"
                >
                  <v-form
                    ref="formConfirm"
                  >
                    <!--set min-height on container in order to make sure entire swish dialog is visible when hiding the form-->
                    <v-container
                      class="mb-12"
                      style="min-height:400px"
                    >
                      <!--hide container when paymentInProgress=true, in order to make sure the payment i progress dialogs (e.g swish) is in view even on mobiles when on the iframe module (can result in long page and the dialog being centered outside current scrolled viewport)-->
                      <v-row
                        v-if="showFinalBookingForm"
                      >
                        <v-col
                          cols="12" sm="6"
                        >
                          <v-card
                            class="mx-auto mb-6 xLightGrayBg"
                          >
                            <v-card-title class="d-flex justify-space-between">
                              <span>Dina val</span>
                              <v-btn
                                color="secondary"
                                small
                                @click="goToStep(2)"
                                outlined
                              >
                                <v-icon left small>
                                  mdi-pencil-outline
                                </v-icon>
                                Ändra
                              </v-btn>
                            </v-card-title>
                            <v-card-text>
                              <div class="text-body-1 font-weight-bold">Event</div>
                              <div class="text-body-1 mb-4">{{ selectedEvent.title }}</div>
                              <template v-if="saloonsSelectableByClient && saloonId != 0">
                                <div class="text-body-1 font-weight-bold">Salong</div>
                                <div class="text-body-1 mb-4">{{ selectedSaloonName }}</div>
                              </template>
                              <div class="text-body-1 font-weight-bold">{{ selectedEventTimes.length == 1 ? 'Tid' : 'Tider' }}</div>
                              <div class="text-body-1 mb-4" v-html="selectedEventsTimeOutput_atFinalStep"></div>
                              <template v-if="multipleAppointmentType_client">
                                <div class="text-body-1 font-weight-bold">Antal personer</div>
                                <div class="text-body-1 mb-4">{{ clientCount }} st</div>
                                <template v-if="priceData.hasCtp">
                                  <div class="mb-8">
                                    <div
                                      v-for="clientType in priceData.clientTypes"
                                      :key="clientType.id"
                                    >
                                      <v-select
                                        v-model="clientType.count"
                                        :items="clientType.counts"
                                        :label="'Varav antal ' + $options.filters.lowercase(clientType.namePlural)"
                                        validate-on-blur
                                        :rules="[formRules.required]"
                                        @change="onClientType"
                                      ></v-select>
                                      <div
                                        v-if="clientType.hasAge"
                                      >
                                        <div
                                          v-for="(n, index) in clientType.count"
                                          :key="index"
                                          class="pl-6"
                                        >
                                          <v-select
                                            v-model="clientType.ages[index]"
                                            :items="childrenAges"
                                            :label="'Ålder på ' + $options.filters.lowercase(clientType.name) + ' ' + n"
                                            validate-on-blur
                                            :rules="[formRules.required]"
                                            @change="onClientType"
                                          ></v-select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </template>
                              </template>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col
                          cols="12" sm="6"
                        >
                          <template
                            v-if="showContactInput"
                          >
                            <template v-if="!selectedEvent.priceData.isFree">
                              <v-card
                                class="mx-auto mb-6 xLightGrayBg"
                              >
                                <v-card-title>Betalningsunderlag</v-card-title>
                                <v-card-text>
                                  <v-simple-table class="xSimpleTable mb-6">
                                    <template v-slot:default>
                                      <tbody>
                                        <tr
                                          v-for="(row, index) of paymentBasis.rows"
                                          :key="index"
                                        >
                                          <td>
                                            {{ row.text }}
                                          </td>
                                          <td class="text-right text-no-wrap">
                                            {{ row.textRight }}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td class="font-weight-bold">
                                            Att betala:
                                          </td>
                                          <td class="text-right font-weight-bold text-no-wrap">
                                            {{ paymentBasis.amountFormatted }}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </template>
                                  </v-simple-table>
                                </v-card-text>
                              </v-card>
                            </template>
                            <v-card
                              class="mx-auto mb-6 xLightGrayBg"
                            >
                              <v-card-title>Dina uppgifter</v-card-title>
                              <v-card-text>
                                <v-text-field
                                  label="Förnamn"
                                  v-model="firstname"
                                  outlined
                                  dense
                                  :rules="[formRules.required]"
                                  validate-on-blur
                                  maxlength="25"
                                ></v-text-field>
                                <v-text-field
                                  label="Efternamn"
                                  v-model="lastname"
                                  outlined
                                  dense
                                  :rules="[formRules.required]"
                                  validate-on-blur
                                  maxlength="25"
                                ></v-text-field>
                                <v-text-field
                                  label="E-post"
                                  v-model="email"
                                  type="email"
                                  outlined
                                  dense
                                  :rules="!isAdmin ? [formRules.required, formRules.email] : []"
                                  validate-on-blur
                                  maxlength="50"
                                ></v-text-field>
                                <v-text-field
                                  label="Upprepa e-post"
                                  v-model="email_confirm"
                                  type="email"
                                  outlined
                                  dense
                                  :rules="!isAdmin ? [formRules.required, formRules.email] : []"
                                  validate-on-blur
                                  maxlength="50"
                                ></v-text-field>
                                <v-text-field
                                  label="Mobilnummer"
                                  type="tel"
                                  v-model="mobile"
                                  outlined
                                  dense
                                  :rules="!isAdmin ? [formRules.required] : []"
                                  validate-on-blur
                                  maxlength="20"
                                ></v-text-field>
                              </v-card-text>
                            </v-card>
                            <v-card
                              class="mx-auto mb-6 xLightGrayBg"
                              v-if="thereIsSomethingToPay"
                            >
                              <v-card-title class="pb-0">Betalning</v-card-title>
                              <v-card-text>
                                <v-radio-group
                                  v-model="selectedPaymentMethod"
                                  :rules="thereIsSomethingToPay ? [formRules.checked(selectedPaymentMethod)] : []"
                                  validate-on-blur
                                >
                                  <v-radio
                                    v-for="paymentMethod of paymentMethods"
                                    :key="paymentMethod.pmt_id"
                                    :value="paymentMethod.pmt_str_label"
                                  >
                                    <template v-slot:label>
                                      <img class="paymentMethodLogo" :src="require('@/assets/' + paymentMethod.pmt_str_logo)" />
                                    </template>
                                  </v-radio>
                                </v-radio-group>
                              </v-card-text>
                            </v-card>
                          </template>
                          <div
                            class="d-flex justify-end px-4"
                          >
                            <v-btn
                              color="primary"
                              @click="confirmBooking()"
                              :disabled="bookingButtonIsDisabled"
                            >
                              {{ bookingButtonOutput }}
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-col>
        </v-row>
      </v-container>
    </transition>
    <!--confirmation-->
    <transition name="view">
      <v-container v-show="view === 'confirmation'">
        <v-row>
          <v-col>
            <div class="text-center text-h5 mb-4">
              Tack för din bokning!
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12" sm="6"
          >
            <v-card
              class="mx-auto pa-0 mb-4"
              outlined
            >
              <v-card-title
                class="d-flex py-2 xCardTitle"
              >
                <div class="text-overline">Bokning</div>
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text
                class="pa-0"
              >
                <v-simple-table class="xSimpleTable">
                  <template v-slot:default>
                    <tbody>
                      <tr
                        v-for="(dataRow, index) of confirmationDataRows"
                        :key="index"
                      >
                        <td>
                          <div class="text-subtitle-2">{{ dataRow.label }}</div>
                        </td>
                        <td>
                          <div
                            v-for="(value, index) of dataRow.values"
                            :key="index"
                            class="text-body"
                          >
                            {{ value }}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12" sm="6"
          >
            <v-card
              class="mx-auto pa-0 mb-4"
              outlined
              v-if="receipt.has"
            >
              <v-card-title
                class="d-flex py-2 xCardTitle"
              >
                <div class="text-overline">Kvitto</div>
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text
                class="pa-0"
              >
                <v-simple-table class="xSimpleTable">
                  <template v-slot:default>
                    <tbody>
                      <tr
                        v-for="(row, index) of receipt.rows"
                        :key="index"
                      >
                        <td>
                          {{ row.text }}
                        </td>
                        <td class="text-right">
                          {{ row.textRight }}
                        </td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold">
                          Totalt:
                        </td>
                        <td class="text-right font-weight-bold">
                          {{ receipt.amount }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="text-center">
              <v-btn
                @click="goHome()"
                color="primary"
              >
                Stäng
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </transition>
    <!--fullEvent-->
    <transition name="view">
      <v-container v-show="view === 'fullEvent'">
        <v-row v-if="fullEvent">
          <v-card>
            <v-col class="px-8 py-8">
              <div class="d-flex mb-4">
                <v-btn
                  color="secondary"
                  @click="goHome()"
                  class="mr-2"
                  small
                  outlined
                >
                  Tillbaka
                </v-btn>
                <v-btn
                  @click="selectEvent(fullEvent)"
                  small
                  color="primary"
                >
                  Boka
                </v-btn>
              </div>
              <div class="text-h5" v-text="fullEvent.title"></div>
              <div class="text-body-2" v-html="fullEvent.description"></div>
            </v-col>
          </v-card>
        </v-row>
      </v-container>
    </transition>
    <!--snack-bar-->
    <app-snack-bar></app-snack-bar>
    <!--loader-->
    <v-overlay
      :value="rootLoader"
      opacity="0.8"
      z-index="1001"
    >
      <v-progress-circular
        :size="60"
        color="secondary"
        indeterminate
      >
      </v-progress-circular>
    </v-overlay>
    <!--swish-->
    <app-swish
      v-if="selectedPaymentMethod == 'swish'"
      :paymentInProgress="paymentInProgress"
      :onCreateData="swishOnCreateData"
      @close="paymentInProgress = false"
      @settled="onConfirmedBooking"
    ></app-swish>
  </v-container>
</template>

<script>
import axios from 'axios'
import SnackBar from '../components/SnackBar'
import Swish from '../components/Swish'
import { snackBarBus } from '../main'
import { mapGetters } from 'vuex'
import { generalMixin } from '../mixins/general'
import { iframeMixin } from '../mixins/iframe'
import { urlMixin } from '../mixins/url'
import { dateMixin } from '../mixins/date'

export default {
  name: 'Home',
  components: {
    'app-snack-bar': SnackBar,
    'app-swish': Swish,
  },
  mixins: [generalMixin, urlMixin, dateMixin, iframeMixin],
  data: () => ({
    view: 'booking',
    bookingStep: 1,
    fullEvent: null,
    events: [],
    slotDays: [],
    selectedEvent: null,
    selectedEventTimes: [],
    slotDayStartDate: undefined,
    dataLoadInProgress: false,
    dataIsLoaded_1: false,
    dataIsLoaded_2: false,
    dataIsLoaded_3: false,
    stepHasBeenInited_2: false,
    clientCount: '',
    clientCounts: [],
    multipleAppointmentType_client: null,
    multipleAppointmentType: null,
    saloonsSelectableByClient: null,
    canBookMultipleSlot: false,
    eventTimeIsJustStart: null,
    maxEventTimeSelectCount: null,
    priceData: {
      clientTypes: []
    },
    saloonId: 0,
    saloons: [],
    rootLoader: false,
    labelEvent: '',
    labelEvents: '',
    paymentMethods: [],
    sessionType: undefined,
    firstname: '',
    lastname: '',
    email: '',
    email_confirm: '',
    mobile: '',
    bookingInProgress: false,
    confirmationDataRows: [],
    receipt: {},
    hasLaterBookableDays: false,
    childrenAges: [],
    showContactInput: false,
    paymentBasis: {
      rows: [],
      amount: 0
    },
    paymentInProgress: false,
    swishOnCreateData: null,
    selectedPaymentMethod: null
  }),
  computed: {
    ...mapGetters(['agent', 'isSingleEvent', 'isEventGroup', 'event', 'eventGroup', 'callbackData', 'agentPrivate', 'isAdmin']),
    dayCountToShow() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 2
        case 'sm': return 4
        case 'md': return 5
        case 'lg': return 7
        case 'xl': return 7
      }
      return 7
    },
    canBrowsePrevDays() {
      return this.eventStartBookingDate !== this.slotDayStartDate
    },
    canBrowseNextDays() {
      return this.dayCountToShow < this.slotDays.length || this.hasLaterBookableDays
    },
    bookTimesButtonText() {
      return this.selectedEventTimes.length < 2 ? 'Boka tiden' : 'Boka ' + this.selectedEventTimes.length + ' tider'
    },
    selectedSaloonName() {
      return this.saloonId == 0 ? '' : this.saloons.find(saloon => saloon.id == this.saloonId).name
    },
    showFinalBookingForm() {
      //we don't want to show the final booking form when a paymentRequest dialog is present, in order to make sure the dialog is visible on small screens on a iframe plugin booking module
      //hide it already on bookingInProgress, due to give the iframe resize script time to react before the dialog overlay's height is set
      return this.bookingStep == 3 && (!this.thereIsSomethingToPay || (!this.paymentInProgress && !this.bookingInProgress))
    },
    /*
    calendarType() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 'custom-daily'
      }
      return 'custom-weekly'
    },
    */
    isDayView() {
      return false
//      return this.calendarType == 'custom-daily'
    },
    showCalendar() {
      return this.view == 'booking' && this.stepHasBeenInited_2 && this.bookingStep == 2 && (!this.multipleAppointmentType_client || this.clientCount != '')
    },
    selectedEventsTimeOutput_atCalendar() {
//      if (this.selectedEventTimes.length == 0) {
      if (this.selectedEventTimes.length != 1) {
        return ''
      }
//      if (this.selectedEventTimes.length > 1) {
//        return this.selectedEventTimes.length + ' tider valda'
//      }
      let output = 'Vald tid: '
      if (this.saloonId != 0) {
        output += this.selectedSaloonName + ' - '
      }
      if (this.eventTimeIsJustStart) {
        output += this.date_outputFriendlyDateTime(this.selectedEventTimes[0].start)
      } else {
        output += this.date_outputFriendlyDateTimeSpan(this.selectedEventTimes[0].start, this.selectedEventTimes[0].end)
      }
      return output
    },
    selectedEventsTimeOutput_atFinalStep() {
      let maxOutputCount = 5
      let loopTo = Math.min(this.selectedEventTimes.length, maxOutputCount - 1)
      let rows = []
      for (let i = 0; i < loopTo; i++) {
        rows.push(this.eventTimeIsJustStart ? this.date_outputFriendlyDateTime(this.selectedEventTimes[i].start) : this.date_outputFriendlyDateTimeSpan(this.selectedEventTimes[i].start, this.selectedEventTimes[i].end))
      }
      let loopedCount = loopTo + 1
      let restCount = this.selectedEventTimes.length - loopedCount
      if (restCount > 0) {
        let comment = (restCount == 1) ? 'Plus en ytterligare tid' : 'Plus ' + restCount + ' ytterligare tider'
        rows.push(comment)
      }
      return rows.join('<br>')
    },
    thereIsSomethingToPay() {
      return this.selectedEvent && !this.isAdmin && this.selectedEvent.priceData.payFirst && this.paymentBasis.amount != 0
    },
    bookingButtonOutput() {
      return this.thereIsSomethingToPay && this.showContactInput ? "Betala med " + this.paymentMethods[0].pmt_str_name : "Boka"
    },
    bookingButtonIsDisabled() {
      return this.bookingInProgress || (this.thereIsSomethingToPay && !this.selectedPaymentMethod)
    }
  },
  watch: {
    bookingInProgress(newValue) {
      this.rootLoader = newValue
    },
    dayCountToShow() {
      if (this.showCalendar) {
        this.getData()
      }
    },
    bookingStep(newValue) {
      switch (newValue) {
        case 1:
          this.resetBookingSelections()
        break
        case 3:
          this.calculatePaymentBasis()
        break;
      }
    },
    clientCount(newValue) {
      switch (this.bookingStep) {
        case 2:
          if (newValue) {
            this.getData();
          }
        break
      }
    },
    thereIsSomethingToPay(newValue) {
      if (newValue) {
        if (this.paymentMethods.length == 1) {
          this.selectedPaymentMethod = this.paymentMethods[0].pmt_str_label
        }
      } else {
        this.selectedPaymentMethod = null
      }
    }
  },
  filters: {
    lowercase(value) {
      return value.toLowerCase()
    }
  },
  methods: {
    calculatePaymentBasis() {
      if (this.selectedEvent.priceData.isFree) {
        //no need to do this for free events
        return
      }
      this.paymentBasis = {
        rows: [],
        amount: 0,
        chargeCount: 1
      }
      switch (this.selectedEvent.multipleAppointmentType) {
        case 'client': {
          //charge per client
          let nonPriceSelectedCount = this.clientCount
          let nonSpecificClientTypeCount = this.clientCount
          if (this.selectedEvent.priceData.chargePerSlot) {
            this.paymentBasis.chargeCount = this.selectedEventTimes.length
          }
          if (this.selectedEvent.priceData.hasCtp) {
            //reset counts and selectedCount
            for (let clientType of this.selectedEvent.priceData.clientTypes) {
              clientType.counts = []
              for (let price of clientType.prices) {
                price.selectedCount = 0
              }
            }
            //update selectedCount for prices depending on form selections
            for (let clientType of this.selectedEvent.priceData.clientTypes) {
              if (this.isPositiveInteger(clientType.count)) {
                //count for clientType is selected
                nonSpecificClientTypeCount -= clientType.count
                if (clientType.hasAge) {
                  //consider selected age counts for current clientType age selects
                  for (let i = 0; i < clientType.count; i++) {
                    if (i < clientType.ages.length && this.isNumeric(clientType.ages[i])) {
                      //loop price ages and check if age is below limit
                      for (let price of clientType.prices) {
                        if (clientType.ages[i] <= price.toAge) {
                          //increment selectedCount
                          price.selectedCount++
                          //do not look for higher age limits
                          break
                        }
                      }
                    }
                  }
                } else {
                  //consider "non-age" count
                  //increment selectedCount
                  clientType.prices[0].selectedCount = clientType.count
                }
              }
            }
            //append paymentBasis.rows
            for (let clientType of this.selectedEvent.priceData.clientTypes) {
              for (let price of clientType.prices) {
                if (price.selectedCount != 0) {
                  //has selectedCount
                  nonPriceSelectedCount -= price.selectedCount
                  let priceToUse = price.price
                  let qty = price.selectedCount
                  let amount = priceToUse * qty
                  let text = qty + ' x ' + clientType.name
                  if (clientType.hasAge) {
                    text += ' ' + price.fromAge + '-' + price.toAge + ' år'
                  }
                  text += ' (' + this.formatPrice(priceToUse, true) + ')'
                  let textRight = this.formatPrice(amount)
                  let row = {
                    mode: 'amount',
                    type: 'client',
                    ctpId: clientType.id,
                    ctaId: price.id,
                    text: text,
                    qty: qty,
                    price: priceToUse,
                    amount: amount,
                    textRight: textRight
                  }
                  if (clientType.hasAge) {
                    row.fromAge = price.fromAge
                    row.toAge = price.toAge
                  }
                  this.paymentBasis.rows.push(row)
                }
              }
            }
            //set selectable counts per clientType
            this.log('nonSpecificClientTypeCount', nonSpecificClientTypeCount)
            for (let clientType of this.selectedEvent.priceData.clientTypes) {
              clientType.counts = []
              let selectedCount = clientType.count ? clientType.count : 0
              this.log(clientType.name + ' selectedCount', selectedCount)
              this.log(clientType.name + ' maxCount', maxCount)
              let maxCount = selectedCount + nonSpecificClientTypeCount
              for (let i = 0; i <= maxCount; i++) {
                clientType.counts.push(i)
              }
            }
          }
          if (nonPriceSelectedCount > 0) {
            //prepend data about "adult" price
            let priceToUse = this.selectedEvent.priceData.price
            let qty = nonPriceSelectedCount
            let amount = priceToUse * qty
            let text = qty + ' x Vuxen (' + this.formatPrice(priceToUse, true) + ')'
            let textRight = this.formatPrice(amount)
            this.paymentBasis.rows.unshift({
              mode: 'amount',
              type: 'client',
              text: text,
              qty: qty,
              price: priceToUse,
              amount: amount,
              textRight: textRight
            })
          }
          if (this.paymentBasis.chargeCount > 1) {
            //charge per slot - multiply with selected time count
            this.paymentBasis.rows.push({
              mode: 'multiplicator',
              type: 'slotCount',
              text: this.paymentBasis.chargeCount + ' x Tider',
              qty: this.paymentBasis.chargeCount,
              textRight: 'x ' + this.paymentBasis.chargeCount
            })
          }
          break
        }
        case 'booking': {
          //charge per booking
          let priceToUse = this.selectedEvent.priceData.price
          let text
          if (this.selectedEvent.priceData.chargePerSlot && this.selectedEventTimes.length > 1) {
            text = this.selectedEventTimes.length + ' x Tider (' + this.formatPrice(priceToUse, true) + ')'
          } else {
            text = 'Bokning'
          }
          let qty = this.selectedEvent.priceData.chargePerSlot ? this.selectedEventTimes.length : 1
          let amount = priceToUse * qty
          let textRight = this.formatPrice(amount)
          this.paymentBasis.rows.push({
            mode: 'amount',
            type: 'booking',
            text: text,
            qty: qty,
            price: priceToUse,
            amount: amount,
            textRight: textRight
          })
          break
        }
      }
      //calculate total amount
      for (let row of this.paymentBasis.rows) {
        switch (row.mode) {
          case 'amount':
            this.paymentBasis.amount += row.amount
          break
          case 'multiplicator':
            this.paymentBasis.amount = this.paymentBasis.amount * row.qty
          break
        }
      }
      this.paymentBasis.amountFormatted = this.formatPrice(this.paymentBasis.amount)
      this.log('rows', this.paymentBasis.rows)
    },
    onClientType() {
      this.calculatePaymentBasis()
      this.setShowContactInput()
    },
    setShowContactInput() {
      //possibly set showContactInput = true
      for (let clientType of this.priceData.clientTypes) {
        if (!this.isNumeric(clientType.count)) {
          this.showContactInput = false
          return
        }
        if (clientType.hasAge) {
          for (let i = 0; i < clientType.count; i++) {
            if ((i + 1) > clientType.ages.length || !this.isNumeric(clientType.ages[i])) {
              this.showContactInput = false
              return
            }
          }
        }
      }
      //still here?
      this.showContactInput = true
    },
    resetBookingSelections() {
//      this.log('resetBookingSelections')
      this.slotDayStartDate = undefined
      this.slotDays = []
      this.selectedEvent = null
      this.selectedEventTimes = []
      this.saloonId = 0
      this.saloons = []
    },
    setSelectedEvent(event) {
      this.resetBookingSelections()
      this.selectedEvent = event
      this.clientCounts = event.clientCounts
      this.canBookMultipleSlot = event.canBookMultipleSlot
      this.eventTimeIsJustStart = event.eventTimeIsJustStart
      this.multipleAppointmentType = event.multipleAppointmentType
      this.multipleAppointmentType_client = this.multipleAppointmentType == 'client'
      this.saloons = event.saloons
      this.saloonsSelectableByClient = event.saloonsSelectableByClient
      this.maxEventTimeSelectCount = event.maxEventTimeSelectCount
      this.sessionType = event.sessionType
      this.priceData = event.priceData
      this.showContactInput = !this.priceData.hasCtp
      //reset clientCount
      this.clientCount = ''
      if (this.selectedEvent.priceData.hasCtp) {
        //reset clientType.count and ages
        for (let clientType of this.selectedEvent.priceData.clientTypes) {
          clientType.count = ''
          clientType.ages = []
        }
      }
    },
    goHome() {
      this.goToStep(1)
    },
    goToStep(step) {
      this.view = 'booking'
      switch (step) {
        case 3:
          if (!this.selectedEventTimes.length) {
            snackBarBus.$emit('message', 'En tid måste väljas först')
            return
          }
          break
      }
      this.bookingStep = step
    },
    onData(step, data) {
//      this.log('onData', step)
      this.setDataIsLoaded(step, true)
      switch(step) {
        case 1:
          this.events = data.events
          this.labelEvent = data.labelEvent
          this.labelEvents = data.labelEvents
          this.paymentMethods = data.paymentMethods
        break
        case 2:
          if (data.invalidity) {
            switch (data.invalidity.reaction) {
              case 'goToEventSelect':
                if (data.invalidity.message) {
                  snackBarBus.$emit('message', data.invalidity.message)
                }
                this.goHome()
              break
            }
          } else {
            this.stepHasBeenInited_2 = true
            switch (this.sessionType) {
              case 'slot':
                this.slotDayStartDate = data.slotDayStartDate
                this.slotDays = this.complementSlotDays(data.slotDays)
                this.eventStartBookingDate = data.eventStartBookingDate
                this.hasLaterBookableDays = data.hasLaterBookableDays
              break
              case 'show':
                //TODO
              break
            }
            if (this.selectedEventTimes.length) {
              //make sure we mark selected event times if its among eventTimes
              for (let slotDay of this.slotDays) {
                for (let eventTime of slotDay.eventTimes) {
                  if (this.eventTimeIsSelected(eventTime)) {
//                    this.log('found one to select!')
                    eventTime.selected = true
                  }
                }
              }
            }
          }
        break
      }
    },
    onSaloonChange() {
      this.slotDays = this.complementSlotDays(this.slotDays)
    },
    complementSlotDays(slotDays) {
//      this.log('complementSlotDays')
      //compose array of saloons to consider
      let isAllSaloons = this.saloonId == 0
      //if isAllSaloons, set saloons to this.saloons except the "all" option - otherwise to an array with the selected saloon only
      let saloons = isAllSaloons ? this.saloons.filter(saloon => saloon.id != 0) : this.saloons.filter(saloon => saloon.id == this.saloonId)
//      this.log('saloonId', this.saloonId)
//      this.log('this.saloons', this.saloons)
//      this.log('saloons', saloons);
      //loop eventTimes
      for (let slotDay of slotDays) {
        for (let eventTime of slotDay.eventTimes) {
          //set isVisible to true if eventTime is selectable for saloon
          let isVisible = isAllSaloons ? true : eventTime.saloons.indexOf(this.saloonId) !== -1
          //init current eventTime as available
          let isAvailable = true
          //init availableCount for eventTime
          let availableCount = 0
          if (!isVisible) {
            //if not visible, also not available
            isAvailable = false
          } else {
            let isAvailable_forAtLeastOneSaloon = false
            for (let saloon of saloons) {
              let isAvailable_currentSaloon = true
              let entireSaloonIsAvailable = true
              if (eventTime.saloonBookedData) {
                //eventTime has saloonBookedData - evaluate it by checking for data per saloon
                if (eventTime.saloonBookedData['id-' + saloon.id]) {
                  //has saloonBookedData for current saloon - evaluate it
                  //consider client/booking count (depending on multipleAppointmentType) and compare it to saloon.appointmentLimit
                  switch (this.multipleAppointmentType) {
                    case 'client':
                      //evaluate clientCount for saloon
                      if (eventTime.saloonBookedData['id-' + saloon.id].clientCount) {
                        let availableCountForSlot = saloon.appointmentLimit - eventTime.saloonBookedData['id-' + saloon.id].clientCount
                        //this means that the saloon has bookings - set entireSaloonIsAvailable = false
                        entireSaloonIsAvailable = false
                        //add availableCountForSlot to availableCount
                        availableCount += availableCountForSlot
                        if (this.clientCount > availableCountForSlot) {
                          //there is not room for the wanted client count in this saloon at this slot
                          isAvailable_currentSaloon = false
                        }
                      }
                    break
                    case 'booking':
                      //evaluate bookingCount for saloon
                      if (eventTime.saloonBookedData['id-' + saloon.id].bookingCount) {
                        let availableCountForSlot = saloon.appointmentLimit - eventTime.saloonBookedData['id-' + saloon.id].bookingCount
                        //this means that the saloon has bookings - set entireSaloonIsAvailable = false
                        entireSaloonIsAvailable = false
                        //add availableCountForSlot to availableCount
                        availableCount += availableCountForSlot
                        if (availableCountForSlot < 1) {
                          //there is no more bookings available for this saloon at this slot
                          isAvailable_currentSaloon = false
                        }
                      }
                    break
                  }
                }
                this.log('saloon.id', saloon.id, eventTime.start, 'isAv', isAvailable_currentSaloon)
              }
              if (isAvailable_currentSaloon) {
                isAvailable_forAtLeastOneSaloon = true
              }
              if (entireSaloonIsAvailable) {
                //add all "seats" of saloon to availableCount
                availableCount += saloon.appointmentLimit
              }
            }
            if (!isAvailable_forAtLeastOneSaloon) {
              //set isAvailable to false if not available for at least one saloon
              isAvailable = false
            }
          }
          //set isVisible for current eventTime
          eventTime.isVisible = isVisible
          //set isAvailable for current eventTime
          eventTime.isAvailable = isAvailable
          //set availableCount
          eventTime.availableCount = availableCount
          if (!isAvailable) {
            //possibly deselect eventTime
            this.deselectEventTime(eventTime)
          }
        }
      }
//      this.log('complementSlotDays', slotDays)
      return slotDays
    },
    eventTimeIsSelected(eventTime) {
      let index = this.getEventTimeIndex(this.selectedEventTimes, eventTime)
      return index != -1
    },
    getEventTimeIndex(eventTimes, eventTime) {
      for (let i = 0; i < eventTimes.length; i++) {
        if (eventTimes[i].start == eventTime.start && eventTimes[i].end == eventTime.end) {
          return i
        }
      }
      return -1
    },
    setDataIsLoaded(step, flag) {
      this['dataIsLoaded_' + step] = flag
    },
    getData(mode) {
      //TODO: fix that we should init a new data request if the ongoing request has another slotDayStartDate than the current value of slotDayStartDate
      this.log('getData')
      if (this.dataLoadInProgress) {
        return
      }
      this.dataLoadInProgress = true
      const step = this.bookingStep
      this.setDataIsLoaded(step, false)
      const postData = {
        agent: this.agent
      }
      if (mode) {
        postData.mode = mode
      }
      const config = {}
      let relUrl
      switch(step) {
        case 1:
          relUrl = '/booking/get-events'
          if (this.isSingleEvent) {
            postData.event = this.event;
          } else if (this.isEventGroup) {
            postData.eventGroup = this.eventGroup;
          }
        break
        case 2:
          postData.event = this.selectedEvent.uuid
          postData.dayCountToShow = this.dayCountToShow
          if (this.slotDayStartDate) {
            postData.slotDayStartDate = this.slotDayStartDate
            let slotDayIndex = Math.min(postData.dayCountToShow - 1, this.slotDays.length - 1)
            postData.slotDayEndDate = this.slotDays[slotDayIndex].date
          }
          if (this.multipleAppointmentType_client) {
            postData.clientCount = this.clientCount
          }
          relUrl = '/booking/get-event-times'
        break
      }
      const url = this.url_composeApiUrl(relUrl)
      this.log('post', postData)
      axios.post(url, postData, config)
        .then(response => {
          this.log('response', response)
          this.dataLoadInProgress = false
          if (response.data.success) {
            //success
            this.onData(step, response.data.returnData)
          } else if (this.onNonSuccessApiCall(response.data.returnData)) {
            snackBarBus.$emit('message', this.generalParseErrorArray(response.data.returnData.error_ar))
          }
        })
        .catch(error => {
          snackBarBus.$emit('message', error.toString())
          this.dataLoadInProgress = false
        })
    },
    reloadSelectedEventData() {
      //TODO: reload selected event data instead of going home
      this.goHome()
    },
    selectEvent(event) {
      this.setSelectedEvent(event);
      this.goToStep(2);
//      this.log('selectEvent multipleAppointmentType_client', event.multipleAppointmentType_client)
      if (!this.multipleAppointmentType_client) {
        this.getData()
      }
    },
    showPrevDays() {
      if (this.canBrowsePrevDays) {
        this.getData('prev')
      }
    },
    showNextDays() {
      if (this.canBrowseNextDays) {
        this.getData('next')
      }
    },
    deselectEventTime(eventTime) {
      let i = this.getEventTimeIndex(this.selectedEventTimes, eventTime)
      if (i != -1) {
        eventTime.selected = false
        //remove from selected array
        this.selectedEventTimes.splice(i, 1);
        return true
      }
      return false
    },
    selectEventTime(eventTime) {
      if (!eventTime.isAvailable) {
        return
      }
      let okToSelect = true
      if (!this.canBookMultipleSlot) {
        //possibly deselect previous in view
        if (eventTime.selected) {
          //deselect this
          okToSelect = false
          this.deselectEventTime(eventTime)
        } else if (this.selectedEventTimes.length) {
          //deselect another
          this.deselectEventTime(this.selectedEventTimes[0])
        }
        //blank selected array
        this.selectedEventTimes = []
      } else {
        //multiple slots bookable - possibly deselect if it is already selected
        if (this.deselectEventTime(eventTime)) {
          //if it was deselected, flag not to select this
          okToSelect = false
        }
        if (okToSelect && this.selectedEventTimes.length >= this.maxEventTimeSelectCount) {
          //has reached selectable limit count
          snackBarBus.$emit('message', 'Du kan max välja ' + this.maxEventTimeSelectCount + ' tider per bokning')
          return
        }
      }
      if (okToSelect) {
        eventTime.selected = true
        this.selectedEventTimes.push(eventTime)
        if (!this.canBookMultipleSlot) {
          //proceed with a little delay, otherwise its like ('eei what just happened?')
          setTimeout(() => {
            this.goToStep(3)
          }, 250)
        }
      }
    },
    bookingFormIsValid() {
      if (this.bookingInProgress) {
        return false
      }
      if (!this.selectedEvent) {
        snackBarBus.$emit('message', 'Ett event måste väljas')
        this.goHome()
        return false
      }
      if (this.selectedEventTimes.length == 0) {
        snackBarBus.$emit('message', 'En tid måste väljas')
        this.goToStep(2)
        return false
      }
      const formIsValid = this.$refs.formConfirm.validate()
      if (!formIsValid) {
        return false
      }
      if (this.email !== this.email_confirm) {
        snackBarBus.$emit('message', 'E-post inte bekräftad korrekt')
        return false
      }
      return true
    },
    confirmBooking() {
      if (!this.bookingFormIsValid()) {
        return
      }
      this.bookingInProgress = true
      const config = {}
      let relUrl = '/booking/booking-request'
      const url = this.url_composeApiUrl(relUrl)
      const postData = {
        agent: this.agent,
        event: this.selectedEvent.uuid,
        eventTimes: this.selectedEventTimes,
        clientCount: this.clientCount,
        slnId: this.saloonId,
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        mobile: this.mobile,
        paymentBasis: this.paymentBasis,
        priceData: this.priceData  
      }
      if (this.isAdmin) {
        postData.agentPrivate = this.agentPrivate
      }
      if (this.thereIsSomethingToPay) {
        postData.paymentMethod = this.selectedPaymentMethod
      }
      this.log('post', postData)
      axios.post(url, postData, config)
        .then(response => {
          this.log('response', response)
          if (response.data.success) {
            //success
            this.onConfirmedBooking(response.data.returnData)
          } else if (this.onNonSuccessApiCall(response.data.returnData)) {
            snackBarBus.$emit('message', this.generalParseErrorArray(response.data.returnData.error_ar))
            this.bookingInProgress = false
          }
        })
        .catch(error => {
          snackBarBus.$emit('message', error.toString())
          this.bookingInProgress = false
        })
    },
    onConfirmedBooking(data) {
      if (data.settled) {
        //show booking confirmation
        this.bookingInProgress = false
        this.paymentInProgress = false
        this.confirmationDataRows = data.confirmationData.dataRows
        this.receipt = data.confirmationData.receipt
  //      this.log('onConfirmedBooking', this.confirmationData)
        this.view = 'confirmation'
      } else {
        //init payment status check
        switch (data.paymentMethod) {
          case 'swish':
            this.swishOnCreateData = data
          break
        }
        this.iframe_scrollToTop()
        this.paymentInProgress = true
        //important to set this to false after we have set paymentInProgress in order to keep the booking form hidden until the payment request is done
        this.bookingInProgress = false
      }
    },
    showFullEvent(event) {
      this.fullEvent = event;
      this.view = 'fullEvent'
    },
    leaveFullEvent() {
      this.view = 'booking'
      this.fullEvent = null;
    },
    considerCallbackData() {
      //TODO: was thought to be used by swish, but swish only uses callback to an app
      alert('considerCallbackData')
      this.log('callbackData', this.callbackData)
    }
  },
  mounted() {
  },
  created() {
    for (let i = 0; i < 19; i++) {
      this.childrenAges.push({
        text: i + ' år',
        value: i
      })
    }
    if (this.callbackData) {
      this.considerCallbackData()
    } else {
      this.getData()
    }
  }
}
</script>
<style>
.c-day-date {
  text-align: center;
  margin-bottom: 6px;
}
.c-day-day {
  text-align: center;
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 6px;
}
.c-day-month {
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 6px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  opacity: 0.6;
}
.c-day-weekday {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 6px;
  text-transform: capitalize;
}
.c-day-time {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 6px;
  height: 50px;
  border: 1px solid #333;
  color: #333;
  margin: 0 0 4px;
  border-radius: 3px;
  cursor: pointer;
}
.c-day-time > div:nth-of-type(1) {
  font-size: 16px;
  line-height: 1em;
}
.c-day-time > div:nth-of-type(2) {
  margin-top: 4px;
  font-size: 13px;
  line-height: 1em;
}
.c-day-time.unavailable {
  border-color:#DDD;
  background-color: #DDD;
  color:#AAA;
  cursor: not-allowed;
}
.c-day-time.selected {
  border-color: dodgerblue;
  background-color:dodgerblue;
  color:white;
}
.c-day-red
,.c-day-red *
{
  color:brown !important;
}
/*
._eventTime {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 1.2em;
  white-space: normal;
  text-align: center;
}
*/
@media (max-width: 600px) {
  /*
  ._eventTime {
    padding: 0 5px;
    font-size: 12px;
  }
  */
  .v-stepper__content {
    padding-left:4px !important;
    padding-right:4px !important;
  }
}
/*
@media (max-width: 300px) {
  ._eventTime {
    padding: 0 1px;
    font-size: 10px;
  }
}
*/
/*
.v-calendar-weekly__day-label {
  margin-bottom:4px !important;
}
.v-calendar-daily {
  height: auto !important;
}
.v-calendar-daily__head {
  margin-right: 0 !important;
}
.v-calendar-daily__scroll-area {
  overflow-y: unset !important;
}
.v-toolbar__title {
  text-transform: capitalize;
}
*/
.v-stepper__header {
  box-shadow: none !important;
}
.xCenter .v-slide-group__content {
  justify-content: center;
}
.xLightGrayBg {
  background-color: #f4f4f4 !important;
}
.xLightGrayBg .v-text-field--outlined .v-input__slot {
  background-color: white !important;
}
img.paymentMethodLogo {
  height:60px
}
</style>